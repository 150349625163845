.main-form {
  padding: 1em;
}

.main-form__title {
  color: var(--charcoal);
  font-size: 19px;
  text-align: center;
  text-shadow: none;
  margin-top: 2px;
  margin-bottom: 4px;
}

.main-form__subtitle {
  color: var(--kelly-green);
  font-size: 15px;
  text-align: center;
  margin-top: 1px;
}

.main-form__step-container {
  background-color: var(--white);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
}

.main-form__step-info {
  font-size: 15px;
  color: var(--kelly-green);
}

.main-form__img-container {
  display: flex;
  justify-content: center;
}

.main-form__img {
  height: 60px;
  margin-top: 5em;
}

@media (min-width: 768px) {
  .main-form__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .main-form {
    padding: 1em 1em 1em 3em;
  }

  .main-form__title {
    color: var(--white);
    font-size: 54px;
    font-weight: 900;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 0;
  }

  .main-form__subtitle {
    color: var(--white);
    font-size: 26px;
    line-height: 38px;
    text-align: left;
    margin-top: 0;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }

  .main-form__step-container {
    width: 460px;
    padding: 20px 40px 30px 40px;
  }

  .main-form__step-info {
    text-align: left;
    font-size: 18px;
    line-height: 38px;
  }

  .main-form__img-container {
    justify-content: right;
  }
}
