.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disclaimer {
  margin-top: 1em;
}

#pixelrgr_pageview_div {
  display: none;
}

@media (min-width: 768px) {
  .disclaimer {
    background: rgba(0, 0, 0, 0.5);
    color: var(--white);
  }
}
