:root {
  --dark-gray: #333333;
  --light-gray: #ebebeb;
  --charcoal: #222222;
  --kelly-green: #2cb701;
  --white: #ffffff;
  --black: #000000;
  --gray: #999999;
  --pale-gray: #f5f5f5;
  --soft-gray: #efefef;
  --medium-gray: #999999;
  --gray-lighter: bfbfbf;
  --dim-gray: #999;
  --gray-medium-light: #bbb;
  --dark-navy: #212529;
  --slate-gray: #495057;
  --red: #dc3545;
  --soft-red: #df3131;
  --charcoal-gray: #686868;
  --dove-gray: #424242;
  --light-pink: #ffdede;
  --blue: #0000EE;
}

body {
  font-family: 'TG';
  background-color: var(--light-gray);
  margin: 0;
}

@media screen and (min-width: 768px) {
  body {
    background-image: url('../img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
  }
}
